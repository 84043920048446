import { RefresherEventDetail } from '@ionic/core';
import {
	IonContent,
	IonItem,
	IonLabel,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonText,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { userActions, walletActions } from '../../redux/actions';
import './Settings.css';
const Settings: React.FC<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const navigate = (route: string) => {
		props.history.push('/tabs' + route);
	};
	function doRefresh(event: CustomEvent<RefresherEventDetail>) {
		const tmpUserToken: string = JSON.parse(localStorage.getItem('NuCoUserToken')!);
		dispatch(walletActions.authorization(tmpUserToken));
		event.detail.complete();
	}
	function handleLogout() {
		dispatch(userActions.logout());
		window.location.reload();
	}
	return (
		<IonPage className="top-page">
			<IonContent className="ion-padding settings-page max-550">
				<IonRefresher slot="fixed" onIonRefresh={doRefresh}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<IonToolbar className="no-background">
					<IonTitle className="ion-text-center" size="large">
						Settings
					</IonTitle>
				</IonToolbar>

				<IonItem button lines="none" onClick={() => navigate('/profile')}>
					<IonLabel>Edit My profile</IonLabel>
				</IonItem>
				<IonItem button lines="none" onClick={() => navigate('/change-password')}>
					<IonLabel>Change Password</IonLabel>
				</IonItem>
				<IonItem button lines="none" onClick={() => navigate('/delete-account')}>
					<IonLabel>Delete My Account</IonLabel>
				</IonItem>
				<IonItem button lines="none" onClick={() => navigate('/privacy')}>
					<IonLabel>Privacy and Security</IonLabel>
				</IonItem>
				<IonItem button lines="none" onClick={() => handleLogout()}>
					<IonLabel>Logout</IonLabel>
				</IonItem>
				<IonText className="version">2.5.2</IonText>
			</IonContent>
		</IonPage>
	);
};

export default Settings;
